import { User as FirebaseUser, signInWithCustomToken } from 'firebase/auth';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FirebaseContextValue } from './firebase.types';
import { firebaseAuth } from './firebase.setup';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  endSignIn,
  initAuth,
  startSignIn,
  throwSignInError,
} from '../../store/auth/auth.slice';
import {
  getIdentity,
  signOut as signOutRequest,
} from '../../store/auth/auth.thunks';
import { BROADCAST_CHANNEL_NAME } from '../../constants/config';
import { BroadcastChannelMessageType } from '../../types/broadcastChannel.types';

const FirebaseContext = createContext<FirebaseContextValue>({
  user: null,
  isLoggedIn: false,
  isInitialized: false,
  isSignInError: false,
  signIn: () => {},
  signOut: () => {},
});

export const FirebaseProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const isLoggedInValue = useAppSelector((state) => state.auth.isLoggedIn);
  const isInitialized = useAppSelector((state) => state.auth.isInitialized);
  const isSignInError = useAppSelector((state) => state.auth.isError.signIn);
  const isSignInLoading = useAppSelector(
    (state) => state.auth.isLoading.signIn
  );

  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);

  const isLoggedIn = isLoggedInValue && !isSignInLoading && !isSignInError;

  const signOut = useCallback(async () => {
    try {
      await dispatch(signOutRequest()).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      firebaseAuth.signOut();
    }
  }, [dispatch]);

  const signIn = useCallback(
    async (token: string) => {
      try {
        dispatch(startSignIn());
        await signInWithCustomToken(firebaseAuth, token);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(throwSignInError());
        firebaseAuth.signOut();
      } finally {
        dispatch(endSignIn());
      }
    },
    [dispatch]
  );

  const firebaseContextValue = useMemo(
    () => ({
      user: firebaseUser,
      isLoggedIn,
      isInitialized,
      isSignInError,
      signIn,
      signOut,
    }),
    [firebaseUser, isInitialized, isLoggedIn, isSignInError, signIn, signOut]
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getIdentity());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((currentUser) => {
      setFirebaseUser(currentUser);
      dispatch(initAuth(!!currentUser));
    });

    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

    const handler = (event: MessageEvent<any>) => {
      if (event.origin !== window.origin) {
        return;
      }

      if (event.data?.type === BroadcastChannelMessageType.LOGOUT) {
        signOut();
        broadcastChannel.removeEventListener('message', handler);
        broadcastChannel.close();
      }
    };

    broadcastChannel.addEventListener('message', handler);

    return () => {
      broadcastChannel.removeEventListener('message', handler);
      broadcastChannel.close();
    };
  }, [signOut]);

  return (
    <FirebaseContext.Provider value={firebaseContextValue}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = (): FirebaseContextValue =>
  useContext(FirebaseContext);
