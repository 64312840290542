import React, { useEffect, useRef, useState } from 'react';
import { Popup } from '../../../components';

type OptionsProps = {
  options: JSX.Element[];
};

export const Options: React.FC<OptionsProps> = ({
  options,
}: OptionsProps): JSX.Element => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as Node;
    const isOptionsTarget = optionsRef.current?.contains(target);
    if (isOptionsTarget) setOpen(!open);
    else if (!isOptionsTarget && open) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => document.removeEventListener('click', handleClick, true);
  });

  return (
    <div ref={optionsRef} className="options">
      <div className="options__dot" />
      <div className="options__dot" />
      <div className="options__dot" />
      <Popup open={open} className="options__popup" options={options} />
    </div>
  );
};
