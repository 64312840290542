import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Avatar, Popup } from '..';
import { ROUTES } from '../../AppRouting';
import { PopupOption } from '../Popup/PopupOption';
import { getCurrentPath } from '../utils';
import { SignOutIcon } from './icons/SignOutIcon';
import { useAppSelector } from '../../store/hooks';
import { useFirebase } from '../../context';

export const Header: React.FC = (): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user);

  const { signOut } = useFirebase();

  const { pathname } = useLocation();
  const currentRoute = ROUTES.find(
    ({ path }) => getCurrentPath(path, user) === pathname
  );

  const [open, setOpen] = useState(false);
  const avatar = useRef<HTMLDivElement>(null);

  const onSignOutClick = () => {
    signOut();
  };

  const options: JSX.Element[] = [
    <PopupOption
      text="Sign out"
      onChoose={onSignOutClick}
      className="header__user-popup__option popup__option"
      Icon={SignOutIcon}
    />,
  ];

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as Node;
    const isAvatarTarget = avatar.current?.contains(target);
    if (!isAvatarTarget && open) setOpen(false);
    else if (isAvatarTarget) setOpen(!open);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  });

  const headerTitle = () => {
    let title;
    if (currentRoute?.label === 'Account') title = 'Account Settings';
    else title = currentRoute?.label;
    return title;
  };

  return (
    <header className="header">
      <h1 className="header__title">{headerTitle()}</h1>
      <div className="header__user">
        <span className="header__user-name">{`${user?.firstName || ''} ${
          user?.lastName || ''
        }`}</span>
        <div ref={avatar}>
          <Avatar
            user={user}
            image={user?.avatar}
            className="header__user-avatar"
          />
        </div>
        <Popup open={open} className="header__user-popup" options={options} />
      </div>
    </header>
  );
};
