import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { CloseIcon } from './CloseIcon';

type ModalProps = {
  title: string;
  children: JSX.Element | string;
  onClose: () => void;
  className?: string;
  open: boolean;
  height?: string;
  width?: string;
  fontSize?: string;
};

export const Modal: React.FC<ModalProps> = ({
  title,
  children,
  onClose,
  className = '',
  open,
  height,
  width,
  fontSize,
}: ModalProps): JSX.Element => {
  return (
    <>
      <div className="modal">
        <CSSTransition unmountOnExit in={open} classNames="modal" timeout={400}>
          <div
            className={`modal__window ${className}`}
            style={{ height, width }}
          >
            <div className="modal__window-bordergradient" />
            <div className="modal__window-title" style={{ fontSize }}>
              {title} <CloseIcon onClick={onClose} />
            </div>
            <div className="modal__window-body">{children}</div>
          </div>
        </CSSTransition>
      </div>
      <CSSTransition unmountOnExit in={open} classNames="modal" timeout={400}>
        <div className="modal__overlay" onClick={onClose} />
      </CSSTransition>
    </>
  );
};
