import React from 'react';

type InputProps = {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  type?: string;
  icon?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  error?: string;
  disabled?: boolean;
};

export const Input: React.FC<InputProps> = ({
  value,
  onChange,
  type = 'text',
  icon = '',
  placeholder,
  label,
  className = '',
  name,
  error = '',
  disabled = false,
}: InputProps): JSX.Element => {
  return (
    <div className={`input ${className}`}>
      <label>
        <span className="input__label">{label}</span>
        <input
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          className={error ? 'error input__control' : 'input__control'}
          type={type}
          disabled={disabled}
        />
        {icon ? (
          <img
            className={label?.length ? 'input__icon' : 'input__icon-no-title'}
            src={icon}
            alt="Icon"
          />
        ) : null}
      </label>
      {error ? <span className="input__error">{error}</span> : null}
    </div>
  );
};
