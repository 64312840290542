import React, { useEffect, useState } from 'react';
import { Features } from '../../../../types';

type ToggleRowProps = {
  formName?: string;
  featureName: string;
  defaultActive?: boolean;
  className?: string;
  disabled?: boolean;
  isHeader?: boolean;
  isMainSwitchActive?: boolean;
  setValue?: (
    field: string,
    value: boolean,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const ToggleRow: React.FC<ToggleRowProps> = ({
  formName = '',
  featureName,
  defaultActive = false,
  className = '',
  disabled = false,
  isHeader,
  isMainSwitchActive = false,
  setValue,
}: ToggleRowProps): JSX.Element => {
  const [active, setActive] = useState(defaultActive);

  const [mainSwitch, setMainSwitch] = useState(isMainSwitchActive);

  useEffect(() => {
    if (active !== defaultActive && !isHeader) {
      setActive(defaultActive);
    } // eslint-disable-next-line
  }, [defaultActive]);

  useEffect(() => {
    if (mainSwitch !== isMainSwitchActive && isHeader) {
      setMainSwitch(isMainSwitchActive);
    } // eslint-disable-next-line
  }, [isMainSwitchActive]);

  const onChangeFlag = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      if (isHeader) {
        setValue(Features.EMAIL_ANALYZER, e.target.checked, true);
        setValue(Features.EMAIL_SIMULATOR, e.target.checked, true);
        setValue(Features.INLINE_MENU, e.target.checked, true);
        setValue(Features.MY_ENGAGEMENTS, e.target.checked, true);
        setValue(Features.MY_INSIGHTS, e.target.checked, true);
        setValue(Features.MY_ANALYTICS, e.target.checked, true);
        setValue(Features.TEAMS, e.target.checked, true);
        setValue(Features.TEAM_ANALYTICS, e.target.checked, true);
        setValue(Features.EVENT_NOTIFICATIONS, e.target.checked, true);
        setValue(Features.X_RAY_ANALYSIS, e.target.checked, true);
        setValue(Features.X_RAY_SIMULATION, e.target.checked, true);
        setValue(Features.WEBHOOKS, e.target.checked, true);
        setValue(Features.SOUNDWAVE, e.target.checked, true);
      } else {
        setValue(formName, e.target.checked, true);
      }
    }
  };

  return (
    <div className={`org-row org-row ${className}`}>
      <span className={isHeader ? 'org-row__label-header' : 'org-row__label'}>
        {featureName}
      </span>
      <label
        className="org-row__toggle"
        style={disabled ? { cursor: 'default', opacity: 0.5 } : {}}
      >
        <input
          readOnly
          checked={isHeader ? mainSwitch : active}
          className="org-row__toggle-control"
          type="checkbox"
          onChange={onChangeFlag}
        />
        <div className="org-row__toggle-circle" />
      </label>
    </div>
  );
};
