import React, { useEffect, useState } from 'react';
import { Button, Modal } from '../../../../components';
import { deleteOrganization } from '../../../../store/orgs/orgs.actions';
import { LoaderIcon } from '../../../../components/Icons/LoaderIcon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

type DeleteOrgModalProps = {
  onClose: () => void;
  open: boolean;
};

export const DeleteOrgModal: React.FC<DeleteOrgModalProps> = ({
  onClose,
  open,
}: DeleteOrgModalProps): JSX.Element => {
  const currentOrg = useAppSelector((state) => state.orgs.currentOrg);
  const deleteOrgStatus = useAppSelector((state) => state.orgs.deleteOrgStatus);

  const [deleted, setDeleted] = useState(false);
  const dispatch = useAppDispatch();

  const { loading, success, error } = deleteOrgStatus;

  useEffect(() => {
    if (success) {
      setDeleted(true);
    }

    return () => setDeleted(false);
  }, [loading, success, error]);

  const handleDelete = () => {
    setDeleted(true);
    if (currentOrg?.orgId) {
      dispatch(deleteOrganization(currentOrg.orgId));
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setDeleted(false);
    }, 300);
    onClose();
  };

  const attrs = {
    delete: {
      Modal: {
        open: open && !deleted,
        onClose,
        title: 'Are you sure?',
      },
      boldText: {
        className: 'delete-org-modal-bold-text',
      },
      buttons: {
        delete: {
          className: 'with-loader delete-org-modal-button',
          onClick: handleDelete,
          variant: 'common' as const,
        },
        cancel: {
          className: 'delete-org-modal-button',
          onClick: onClose,
          variant: 'text' as const,
        },
        loader: {
          className: 'delete-org-modal-button-loader',
        },
      },
    },

    success: {
      Modal: {
        open: open && deleted,
        onClose: handleClose,
        title: 'Organization removed',
      },
      boldText: {
        className: 'delete-org-modal-bold-text',
      },
      button: {
        onClick: handleClose,
        variant: 'common' as const,
        className: 'delete-org-modal-button',
      },
    },
  };

  if (open && deleted) {
    return (
      <Modal {...attrs.success.Modal}>
        <div>
          <span {...attrs.success.boldText}>{currentOrg?.name}</span> has been{' '}
          <span {...attrs.success.boldText}>removed</span> from organizations
          <Button {...attrs.success.button}>Got it</Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal {...attrs.delete.Modal}>
      <div>
        <div>
          Are you sure you would like to delete{' '}
          <span {...attrs.delete.boldText}>{currentOrg?.name}</span>?
        </div>
        <Button {...attrs.delete.buttons.delete}>
          {loading ? (
            <LoaderIcon {...attrs.delete.buttons.loader} />
          ) : (
            <>Delete</>
          )}
        </Button>
        <Button {...attrs.delete.buttons.cancel}>Cancel</Button>
      </div>
    </Modal>
  );
};
