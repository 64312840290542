import React, { useState } from 'react';
import { SidebarsIconTooltip } from '../Sidebar/sidebarTooltip/SidebarsIconTooltip';
import { circleColor, IconProps, strokeColor } from './icons.utils';

export const OrganizationsIcon: React.FC<IconProps> = ({
  active,
  className = '',
  tooltip = '',
}: IconProps): JSX.Element => {
  const [hover, setIsHover] = useState(false);
  const onEnter = () => {
    setIsHover(true);
  };
  const onLeave = () => {
    setIsHover(false);
  };
  return (
    <div
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      className="sidebar__nav-link__icon__wrapper"
    >
      <SidebarsIconTooltip isActive={hover} label={tooltip} />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <circle cx="11.9995" cy="12" r="12" fill={circleColor(active)} />
        <rect
          x="3"
          y="13.6113"
          width="4"
          height="4"
          rx="1"
          stroke={strokeColor(active)}
          strokeWidth="2"
        />
        <rect
          x="17"
          y="13.6113"
          width="4"
          height="4"
          rx="1"
          stroke={strokeColor(active)}
          strokeWidth="2"
        />
        <rect
          x="9"
          y="4.61133"
          width="6"
          height="3"
          rx="1"
          stroke={strokeColor(active)}
          strokeWidth="2"
        />
        <rect
          x="12.5148"
          y="9.12633"
          width="3.53507"
          height="1.03"
          transform="rotate(90 12.5148 9.12633)"
          stroke={strokeColor(active)}
          strokeWidth="1.03"
        />
        <rect
          x="11.6491"
          y="11.485"
          width="7.01164"
          height="1.03"
          rx="0.515"
          transform="rotate(-180 11.6491 11.485)"
          stroke={strokeColor(active)}
          strokeWidth="1.03"
        />
        <rect
          x="19.5148"
          y="11.485"
          width="6.83553"
          height="1.03"
          rx="0.515"
          transform="rotate(-180 19.5148 11.485)"
          stroke={strokeColor(active)}
          strokeWidth="1.03"
        />
        <rect
          x="5.66762"
          y="10.4549"
          width="2.77428"
          height="1.03"
          rx="0.515"
          transform="rotate(90 5.66762 10.4549)"
          stroke={strokeColor(active)}
          strokeWidth="1.03"
        />
        <rect
          x="19.5148"
          y="10.4549"
          width="2.77428"
          height="1.03"
          rx="0.515"
          transform="rotate(90 19.5148 10.4549)"
          stroke={strokeColor(active)}
          strokeWidth="1.03"
        />
        <rect
          x="10"
          y="13.6113"
          width="4"
          height="4"
          rx="1"
          stroke={strokeColor(active)}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};
