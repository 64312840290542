import React from 'react';
import { Button } from '../../components';
import { ACCOUNT_URL } from '../../constants/config';
import noPermission from './no-permission.svg';

export const NoPermission: React.FC = (): JSX.Element => {
  const handleRedirect = () => {
    window.location.href = `${ACCOUNT_URL}`;
  };

  return (
    <div className="no-permission">
      <div className="no-permission__text">
        <h1>You shall not pass</h1>
        <p>You do not have the appropriate permissions to view this page</p>

        <Button
          className="no-permission__text-btn"
          variant="common"
          onClick={handleRedirect}
        >
          Take me to my account
        </Button>
      </div>
      <img src={noPermission} alt="No permission" />
    </div>
  );
};
