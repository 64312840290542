import { Reducer } from 'redux';
import { OrgRow } from '../../pages/Organizations/organizations.utils';
import { Organization } from '../../types';
import { updateOrgForTable } from './org.utils';
import {
  CREATE_ORG,
  CREATE_ORG_ERROR,
  CREATE_ORG_LOADING,
  CREATE_ORG_SUCCESS,
  DELETE_ORG,
  DELETE_ORG_ERROR,
  DELETE_ORG_LOADING,
  DELETE_ORG_SUCCESS,
  EDIT_ORG,
  EDIT_ORG_ERROR,
  EDIT_ORG_LOADING,
  EDIT_ORG_SUCCESS,
  GET_ORGS,
  GET_ORGS_LOADING,
  INVOKE_DELETE_ORG_MODAL,
  RESET_CREATE_ORG_STATUS,
  RESET_DELETE_ORG_STATUS,
  RESET_EDIT_ORG_STATUS,
  SET_CURRENT_ORG,
  SET_ERROR_MESSAGE,
} from './orgs.actions';
import { OrgsActions } from './orgs.types';

export type OrgsState = {
  orgs: OrgRow[];
  currentOrg: Organization | null;
  createOrgStatus: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  editOrgStatus: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  deleteOrgStatus: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
  isOrgCreated: boolean;
  successMsg: string;
  isErrorMsg: boolean;
  deleteModalOpen: boolean;
  status: {
    orgListLoading: boolean;
    editLoading: boolean;
  };
};

const initialState: OrgsState = {
  orgs: [],
  currentOrg: null,
  createOrgStatus: {
    loading: false,
    error: false,
    success: false,
  },
  editOrgStatus: {
    loading: false,
    error: false,
    success: false,
  },
  deleteOrgStatus: {
    loading: false,
    error: false,
    success: false,
  },
  isOrgCreated: false,
  successMsg: '',
  isErrorMsg: false,
  deleteModalOpen: false,
  status: {
    orgListLoading: false,
    editLoading: false,
  },
};

export const orgsReducer: Reducer<OrgsState, OrgsActions> = (
  state: OrgsState = initialState,
  action: OrgsActions
): OrgsState => {
  switch (action.type) {
    case GET_ORGS:
      return {
        ...state,
        orgs: action.payload.map((org) => updateOrgForTable(org)),
        currentOrg: null,
      };
    case GET_ORGS_LOADING:
      return {
        ...state,
        status: { ...state.status, orgListLoading: action.flag },
      };

    case CREATE_ORG:
      return {
        ...state,
        successMsg: 'Added successfully',
      };
    case CREATE_ORG_LOADING:
      return {
        ...state,
        createOrgStatus: { ...state.createOrgStatus, loading: action.flag },
      };
    case CREATE_ORG_SUCCESS:
      return {
        ...state,
        isOrgCreated: action.flag,
        createOrgStatus: { ...state.createOrgStatus, success: action.flag },
      };
    case CREATE_ORG_ERROR:
      return {
        ...state,
        createOrgStatus: { ...state.createOrgStatus, error: action.flag },
      };
    case RESET_CREATE_ORG_STATUS:
      return {
        ...state,
        createOrgStatus: initialState.createOrgStatus,
      };

    case EDIT_ORG:
      return {
        ...state,
        orgs: state.orgs.map((org) =>
          org.email === action.payload.org.primaryEmail
            ? updateOrgForTable(action.payload.org)
            : org
        ),
      };
    case EDIT_ORG_LOADING:
      return {
        ...state,
        status: { ...state.status, editLoading: action.flag },
        editOrgStatus: { ...state.editOrgStatus, loading: action.flag },
      };
    case EDIT_ORG_SUCCESS:
      return {
        ...state,
        editOrgStatus: { ...state.editOrgStatus, success: action.flag },
      };
    case EDIT_ORG_ERROR:
      return {
        ...state,
        editOrgStatus: { ...state.editOrgStatus, error: action.flag },
      };
    case RESET_EDIT_ORG_STATUS:
      return {
        ...state,
        editOrgStatus: initialState.editOrgStatus,
      };

    case SET_CURRENT_ORG:
      return {
        ...state,
        currentOrg: action.payload,
      };

    case INVOKE_DELETE_ORG_MODAL:
      return {
        ...state,
        deleteModalOpen: !state.deleteModalOpen,
      };

    case DELETE_ORG:
      return {
        ...state,
        orgs: state.orgs.filter((org) => org.key !== action.payload),
      };
    case DELETE_ORG_LOADING:
      return {
        ...state,
        deleteOrgStatus: { ...state.deleteOrgStatus, loading: action.flag },
      };
    case DELETE_ORG_SUCCESS:
      return {
        ...state,
        deleteOrgStatus: { ...state.deleteOrgStatus, success: action.flag },
      };
    case DELETE_ORG_ERROR:
      return {
        ...state,
        deleteOrgStatus: { ...state.deleteOrgStatus, error: action.flag },
      };
    case RESET_DELETE_ORG_STATUS:
      return {
        ...state,
        deleteOrgStatus: initialState.deleteOrgStatus,
      };

    case SET_ERROR_MESSAGE:
      return {
        ...state,
        isErrorMsg: action.flag,
      };

    default:
      return state;
  }
};
