import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import {
  CreateOrganizationAction,
  CreateOrgError,
  CreateOrgLoading,
  CreateOrgSuccess,
  DeleteOrgAction,
  DeleteOrgErrorAction,
  DeleteOrgLoadingAction,
  DeleteOrgSuccessAction,
  EditOrganizationAction,
  EditOrgErrorAction,
  EditOrgLoadingAction,
  EditOrgSuccessAction,
  GetOrgsAction,
  GetOrgsLoadingAction,
  InvokeDeleteOrgModalAction,
  ResetCreateOrgStatus,
  ResetDeleteOrgStatus,
  ResetEditOrgStatus,
  SetCurrentOrgAction,
  SetErrorMessage,
  UpdateOrgValues,
} from './orgs.types';
import axios from '../../axios';
import { Organization } from '../../types';
import { AddOrgForm } from '../../pages/Organizations/components/AddOrganization/useOrgForm';
import { addOrgFeatures, addOrgFeaturesOnUpdate } from './org.utils';
import { throwCreateOrgError } from './orgs.slice';

export const GET_ORGS = 'GET_ORGS';
export const GET_ORGS_LOADING = 'GET_ORGS_LOADING';

export const CREATE_ORG = 'CREATE_ORG';
export const CREATE_ORG_LOADING = 'CREATE_ORG_LOADING';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const CREATE_ORG_ERROR = 'CREATE_ORG_ERROR';
export const RESET_CREATE_ORG_STATUS = 'RESET_CREATE_ORG_STATUS';

export const EDIT_ORG = 'EDIT_ORG';
export const EDIT_ORG_LOADING = 'EDIT_ORG_LOADING';
export const EDIT_ORG_SUCCESS = 'EDIT_ORG_SUCCESS';
export const EDIT_ORG_ERROR = 'EDIT_ORG_SUCCESS';
export const RESET_EDIT_ORG_STATUS = 'RESET_EDIT_ORG_STATUS';

export const DELETE_ORG = 'DELETE_ORG';
export const DELETE_ORG_LOADING = 'DELETE_ORG_LOADING';
export const DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS';
export const DELETE_ORG_ERROR = 'DELETE_ORG_ERROR';
export const RESET_DELETE_ORG_STATUS = 'RESET_DELETE_ORG_STATUS';

export const SET_CURRENT_ORG = 'SET_CURRENT_ORG';

export const INVOKE_DELETE_ORG_MODAL = 'INVOKE_DELETE_ORG_MODAL';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const RESEND_INVITE = 'RESEND_INVITE';

export const getOrgs = () => async (
  dispatch: Dispatch<GetOrgsAction | GetOrgsLoadingAction>
): Promise<void> => {
  try {
    dispatch({
      type: GET_ORGS_LOADING,
      flag: true,
    });

    const orgs = await axios.get('su/orgs/');

    dispatch({
      type: GET_ORGS,
      payload: addOrgFeatures(orgs.data.payload),
    });

    dispatch({
      type: GET_ORGS_LOADING,
      flag: false,
    });
  } catch (e) {
    dispatch({
      type: GET_ORGS_LOADING,
      flag: false,
    });
  }
};

export const addOrganization = (
  values: AddOrgForm,
  orgId: string,
  userId: string
) => async (
  dispatch: Dispatch<
    | CreateOrganizationAction
    | EditOrgLoadingAction
    | CreateOrgLoading
    | CreateOrgError
    | CreateOrgSuccess
    | EditOrgErrorAction
    | GetOrgsAction
    | SetErrorMessage
  >
): Promise<void> => {
  try {
    dispatch({
      type: EDIT_ORG_LOADING,
      flag: true,
    });
    const orgInviteBody = { ...values, inviteType: 'ownerInvite' };
    const response = await axios.post(
      `su/invites?requesterId=${userId}`,
      orgInviteBody
    );
    if (response.status === 200) {
      const updatedOrgs = await axios.get('su/orgs/');
      dispatch({
        type: CREATE_ORG_SUCCESS,
        flag: true,
      });
      dispatch({
        type: GET_ORGS,
        payload: addOrgFeatures(updatedOrgs.data.payload),
      });
      dispatch({
        type: EDIT_ORG_LOADING,
        flag: false,
      });
    }
  } catch (e) {
    dispatch({
      type: EDIT_ORG_LOADING,
      flag: false,
    });

    if (e instanceof AxiosError && e.response?.status === 404) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(throwCreateOrgError());
      return;
    }

    dispatch({
      type: SET_ERROR_MESSAGE,
      flag: true,
    });
  }
};

export const resendInvite = (userId: string, orgId: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  const orgBody = {
    orgId,
    inviteType: 'ownerInvite',
  };
  try {
    await axios.post(`/su/invites/resend?requesterId=${userId}`, orgBody);
    dispatch({
      type: RESEND_INVITE,
    });
  } catch (e) {
    // err
  }
};

export const createOrgSuccess = (flag: boolean): CreateOrgSuccess => ({
  type: CREATE_ORG_SUCCESS,
  flag,
});

export const updateOrganization = (
  values: UpdateOrgValues,
  orgId: string,
  isCurrentOrg: boolean
) => async (
  dispatch: Dispatch<
    | EditOrganizationAction
    | EditOrgLoadingAction
    | EditOrgSuccessAction
    | EditOrgErrorAction
  >
): Promise<void> => {
  try {
    dispatch({
      type: EDIT_ORG_LOADING,
      flag: true,
    });
    const updatedOrgResponse = await axios.patch(`su/orgs/${orgId}`, {
      ...values,
    });
    dispatch({
      type: EDIT_ORG,
      payload: {
        org: addOrgFeaturesOnUpdate(updatedOrgResponse.data.payload),
        isCurrentOrg,
      },
    });
    dispatch({
      type: EDIT_ORG_LOADING,
      flag: false,
    });
    dispatch({
      type: EDIT_ORG_SUCCESS,
      flag: true,
    });
  } catch (e) {
    dispatch({
      type: EDIT_ORG_LOADING,
      flag: false,
    });
    dispatch({
      type: EDIT_ORG_SUCCESS,
      flag: false,
    });
  }
};

export const setCurrentOrg = (org: Organization): SetCurrentOrgAction => ({
  type: SET_CURRENT_ORG,
  payload: org,
});

export const invokeDeleteOrgModal = (): InvokeDeleteOrgModalAction => ({
  type: INVOKE_DELETE_ORG_MODAL,
});

export const deleteOrganization = (orgId: string) => async (
  dispatch: Dispatch<
    | DeleteOrgAction
    | DeleteOrgLoadingAction
    | DeleteOrgSuccessAction
    | DeleteOrgErrorAction
  >
): Promise<void> => {
  try {
    dispatch({
      type: DELETE_ORG_LOADING,
      flag: true,
    });

    await axios.delete(`su/orgs/${orgId}`);

    dispatch({
      type: DELETE_ORG,
      payload: orgId,
    });
    dispatch({
      type: DELETE_ORG_SUCCESS,
      flag: true,
    });
    dispatch({
      type: DELETE_ORG_LOADING,
      flag: false,
    });
  } catch (e) {
    dispatch({
      type: DELETE_ORG_ERROR,
      flag: true,
    });
    dispatch({
      type: DELETE_ORG_LOADING,
      flag: false,
    });
    dispatch({
      type: DELETE_ORG_SUCCESS,
      flag: true,
    });
    // err
  }
};

export const resetCreateOrgStatus = (): ResetCreateOrgStatus => ({
  type: RESET_CREATE_ORG_STATUS,
});
export const resetEditOrgStatus = (): ResetEditOrgStatus => ({
  type: RESET_EDIT_ORG_STATUS,
});
export const resetDeleteOrgStatus = (): ResetDeleteOrgStatus => ({
  type: RESET_DELETE_ORG_STATUS,
});

export const clearOrgErrorMessage = (): SetErrorMessage => ({
  type: SET_ERROR_MESSAGE,
  flag: false,
});
