import React from 'react';
import { InviteStatus } from '../../../types';

type InvitationStatusProps = {
  status: InviteStatus;
};

export const InvitationStatus: React.FC<InvitationStatusProps> = ({
  status,
}: InvitationStatusProps): JSX.Element => {
  return <div className="invitation-status">Invitation {status}</div>;
};
