import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { OrganizationsIcon } from './components/Icons/OrganizationsIcon';
import { Organizations } from './pages/Organizations/Organizations';
import { Role, RouteI, User } from './types';
import { useFirebase } from './context';
import { useAppSelector } from './store/hooks';

export const ROUTES: RouteI[] = [
  {
    path: '/orgs',
    Component: Organizations,
    label: 'Organizations',
    tooltip: 'Organizations',
    Icon: OrganizationsIcon,
    roles: ['superAdmin'],
  },
];

interface ProtectedRouteProps extends RouteProps {
  roles: string[];
  user: User | null;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  roles,
  user,
  children,
  ...props
}: ProtectedRouteProps): JSX.Element | null => {
  const { isInitialized, isLoggedIn } = useFirebase();

  if (isInitialized && !isLoggedIn) {
    return <Redirect to="/" />;
  }

  return roles.includes(user?.role as string) && user?.isEmailVerified ? (
    <Route {...props}>
      {({ match }) => (
        <CSSTransition timeout={500} classNames="routes" in={match != null}>
          {children}
        </CSSTransition>
      )}
    </Route>
  ) : null;
};

export const AppRouting: React.FC = (): JSX.Element | null => {
  const user = useAppSelector((state) => state.auth.user);

  const history = useHistory();

  useEffect(() => {
    if (user && user.role !== Role.SUPER_ADMIN) {
      history.push('/no-permission');
    }
  }, [history, user]);

  return (
    <TransitionGroup>
      <Switch>
        {ROUTES.map((route) => {
          const { path, Component, roles } = route;
          return (
            <ProtectedRoute
              roles={roles}
              key={path}
              exact
              path={path}
              user={user}
            >
              <Component />
            </ProtectedRoute>
          );
        })}
        <Route path="*">
          <Redirect to="/not-found" />
        </Route>
      </Switch>
    </TransitionGroup>
  );
};
