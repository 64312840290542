import React from 'react';

type SidebarsIconTooltipProps = {
  isActive: boolean;
  label: string;
  isSupport?: boolean;
};

export const SidebarsIconTooltip: React.FC<SidebarsIconTooltipProps> = ({
  isActive = false,
  label = '',
  isSupport = false,
}: SidebarsIconTooltipProps): JSX.Element => {
  return (
    <div
      className={
        isActive
          ? 'sidebar__nav-link__icon__tooltip'
          : 'inactive sidebar__nav-link__icon__tooltip'
      }
      style={isSupport ? { marginLeft: '35px' } : {}}
    >
      <span className="sidebar__nav-link__icon__tooltip__label">{label}</span>
    </div>
  );
};
