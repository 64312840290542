import { useState } from 'react';

type Toggle = {
  open: boolean;
  toggle: () => void;
};

export const useToggle = (defaultOpen = true): Toggle => {
  const [open, setOpen] = useState(defaultOpen);

  const toggle = () => {
    setOpen(!open);
  };

  return { open, toggle };
};
