import React, { useState } from 'react';
import { SidebarsIconTooltip } from '../sidebarTooltip/SidebarsIconTooltip';

export interface IconProps {
  active: boolean;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
}

export const SupportIcon: React.FC = (): JSX.Element => {
  const [hover, setIsHover] = useState(false);
  const onEnter = () => {
    setIsHover(true);
  };
  const onLeave = () => {
    setIsHover(false);
  };

  const circleColor = (active: boolean): string =>
    active ? '#5C6483' : '#29335C';

  const circleFill = hover ? '#ffffff' : circleColor(hover);

  return (
    <div onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <SidebarsIconTooltip isActive={hover} label="Support" isSupport />
      <svg
        width="24"
        height="24"
        viewBox="0 -3 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="7.9"
          r="7.7"
          fill={circleFill}
          style={hover ? { opacity: '0.2' } : {}}
        />
        <g opacity="0.8">
          <circle cx="8" cy="8" r="7.5" stroke="#ffffff" />
        </g>
        <path
          opacity="0.8"
          d="M6.19 6.91406H5.35C5.35 6.55406 5.42333 6.2374 5.57 5.96406C5.72333 5.68406 5.92667 5.4674 6.18 5.31406C6.44 5.15406 6.72333 5.03406 7.03 4.95406C7.33667 4.87406 7.66333 4.83406 8.01 4.83406C8.36333 4.83406 8.69333 4.87073 9 4.94406C9.30667 5.0174 9.59 5.13073 9.85 5.28406C10.11 5.4374 10.3133 5.65406 10.46 5.93406C10.6133 6.2074 10.69 6.53073 10.69 6.90406C10.69 7.13073 10.66 7.3374 10.6 7.52406C10.54 7.70406 10.4767 7.85073 10.41 7.96406C10.3433 8.07073 10.2333 8.18073 10.08 8.29406C9.92667 8.4074 9.81667 8.48406 9.75 8.52406C9.68333 8.5574 9.55333 8.6174 9.36 8.70406C9.16667 8.78406 9.06 8.8274 9.04 8.83406C8.69333 8.9674 8.46667 9.12073 8.36 9.29406C8.25333 9.4674 8.2 9.7774 8.2 10.2241H7.33C7.33 9.58406 7.43333 9.12406 7.64 8.84406C7.84667 8.5574 8.19333 8.3274 8.68 8.15406C8.86667 8.08073 9.00667 8.02073 9.1 7.97406C9.19333 7.9274 9.30667 7.85406 9.44 7.75406C9.57333 7.65406 9.67 7.53406 9.73 7.39406C9.79 7.2474 9.82 7.0774 9.82 6.88406C9.82 6.63073 9.76333 6.41406 9.65 6.23406C9.53667 6.05406 9.38333 5.92406 9.19 5.84406C8.99667 5.7574 8.8 5.6974 8.6 5.66406C8.40667 5.62406 8.19667 5.60406 7.97 5.60406C7.68333 5.60406 7.41 5.64406 7.15 5.72406C6.89667 5.80406 6.67333 5.9474 6.48 6.15406C6.28667 6.35406 6.19 6.6074 6.19 6.91406ZM7.51 11.8841C7.32333 11.7841 7.23 11.6141 7.23 11.3741C7.23 11.1341 7.32333 10.9674 7.51 10.8741C7.70333 10.7741 7.89667 10.7741 8.09 10.8741C8.28333 10.9674 8.38 11.1341 8.38 11.3741C8.38 11.6141 8.28333 11.7841 8.09 11.8841C7.89667 11.9774 7.70333 11.9774 7.51 11.8841Z"
          fill="#ffffff"
        />
      </svg>
    </div>
  );
};
