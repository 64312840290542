import React from 'react';
import { HeaderIconProps } from './types';

export const SignOutIcon: React.FC<HeaderIconProps> = ({
  disabled = false,
}: HeaderIconProps): JSX.Element => {
  const color = disabled ? '#cccccc' : '#ffffff';
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76991 8.19365L1.91133 8.19365L1.91133 1.80603L3.76991 1.80603C4.26697 1.80603 4.66991 1.40308 4.66991 0.906027C4.66991 0.408971 4.26697 0.00602609 3.76991 0.00602611L1.01152 0.00602623L1.00851 0.00603195C0.512752 0.00754927 0.111328 0.40991 0.111328 0.906028L0.111328 9.09328C0.111328 9.24555 0.149142 9.38899 0.215891 9.51471C0.366972 9.7996 0.666586 9.99365 1.01152 9.99365L3.76991 9.99365C4.26697 9.99365 4.66991 9.59071 4.66991 9.09365C4.66991 8.5966 4.26697 8.19365 3.76991 8.19365ZM4.03496 5.89965C3.5379 5.89965 3.13496 5.49671 3.13496 4.99965C3.13496 4.5026 3.5379 4.09965 4.03496 4.09965L6.4995 4.09965L6.4995 3.68593C6.4995 2.81382 7.53793 2.35962 8.17832 2.95162L9.59943 4.26535C10.0277 4.66123 10.0277 5.33808 9.59943 5.73396L8.17832 7.04769C7.53793 7.63969 6.4995 7.18549 6.4995 6.31338L6.4995 5.89965L4.03496 5.89965Z"
        fill={color}
      />
    </svg>
  );
};
