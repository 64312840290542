import React from 'react';
import { useTooltip } from '../hooks/useTooltip';
import { Tooltip } from '../Tooltip/Tooltip';

type AddIconProps = {
  onClick: () => void;
  tooltipText: string;
  width?: string;
};

export const AddIcon: React.FC<AddIconProps> = ({
  onClick,
  tooltipText,
  width,
}: AddIconProps): JSX.Element => {
  const { handleToggleTooltip, open } = useTooltip();

  return (
    <div className="table__icon">
      <Tooltip open={open} text={tooltipText} width={width} />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={handleToggleTooltip}
        onMouseLeave={handleToggleTooltip}
        className="table__icon__icon"
        onClick={onClick}
      >
        <rect
          x="11.2002"
          y="5"
          width="2"
          height="14.4003"
          rx="1"
          fill="#394577"
        />
        <rect
          x="19.4004"
          y="11.2002"
          width="2"
          height="14.4003"
          rx="1"
          transform="rotate(90 19.4004 11.2002)"
          fill="#394577"
        />
      </svg>
    </div>
  );
};
