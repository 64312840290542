import React, { useState, useEffect, useCallback } from 'react';
import { PopupOption } from '../../components/Popup/PopupOption';
import {
  getOrgs,
  invokeDeleteOrgModal,
  resendInvite,
  setCurrentOrg,
} from '../../store/orgs/orgs.actions';
import { Organization } from '../../types';
import { InvitationStatus } from './components/InvitationStatus';
import { Options } from './components/Options';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export type OrgRow = {
  key: string;
  name: string;
  url: string;
  email: string;
  invitationSent?: 'sent' | 'expired' | 'accepted';
  userSeats?: string;
  acquisitionSource?: string | null;
  options: Organization;
};

type Column = {
  title: string;
  dataIndex: string;
  key: string;
  width?: string;
  className?: string;
  render?: (...params: any[]) => any; // eslint-disable-line
};

type UseTable = {
  rowSelection: {
    onChange: (keys: React.Key[], rows: OrgRow[]) => void;
  };
  handleSelect: (keys: React.Key[], rows: OrgRow[]) => void;
  selectedRows: OrgRow[];
  cols: Column[];
  orgs: OrgRow[];
  handleDelete: () => void;
  editOpen: boolean;
  handleEdit: () => void;
};

export const getCols = (
  options: JSX.Element[],
  findCurrentOrg: (org: Organization) => void
): Column[] => [
  {
    title: 'Organization Name',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '25%',
    className: 'table-url',
  },
  {
    title: 'Primary Email Address',
    dataIndex: 'email',
    key: 'email',
    width: '20%',
  },
  {
    title: 'Number of Seats',
    dataIndex: 'userSeats',
    key: 'userSeats',
    width: '10%',
    className: 'user__seats',
  },
  {
    title: 'Acq. source',
    dataIndex: 'acquisitionSource',
    key: 'acquisitionSource',
  },
  {
    title: 'Invite Status',
    dataIndex: 'invitationSent',
    key: 'invitationSent',
    render: (status: 'sent' | 'expired'): JSX.Element | null =>
      status && status === 'sent' ? <InvitationStatus status={status} /> : null,
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    width: '50px',
    render: (org: Organization): JSX.Element => (
      <div onClick={() => findCurrentOrg(org)}>
        <Options options={options} />
      </div>
    ),
  },
];

export const useOrgTable = (): UseTable => {
  const [selectedRows, setSelectedRows] = useState<OrgRow[]>([]);
  const [editOpen, setEditOpen] = useState(false);
  const orgs = useAppSelector((state) => state.orgs.orgs);
  const currentOrg = useAppSelector((state) => state.orgs.currentOrg);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!orgs.length) dispatch(getOrgs());
  }, [orgs.length, dispatch]);

  const handleSelect = (keys: React.Key[], rows: OrgRow[]): void => {
    setSelectedRows(rows);
  };

  const rowSelection = {
    onChange: handleSelect,
  };

  const handleDelete = () => {
    dispatch(invokeDeleteOrgModal());
  };

  const handleEdit = () => {
    setEditOpen(!editOpen);
  };

  const handleResendInvite = useCallback(() => {
    if (user?.userId && currentOrg?.orgId)
      dispatch(resendInvite(user.userId, currentOrg?.orgId));
    const timer = setTimeout(() => {
      dispatch(getOrgs());
    }, 1000);
    return () => clearTimeout(timer); // eslint-disable-next-line
  }, [currentOrg, dispatch, currentOrg?.orgId]);

  const conditionShowInvitation =
    currentOrg?.inviteStatus && currentOrg.inviteStatus !== 'accepted';

  const options: JSX.Element[] = [
    <PopupOption
      className="options__option"
      text="Edit organization"
      onChoose={handleEdit}
    />,
    ...(conditionShowInvitation
      ? [
          <PopupOption
            className="options__option"
            text="Resend invitation"
            onChoose={handleResendInvite}
          />,
        ]
      : []),
    <PopupOption
      className="options__option options__red"
      text="Delete organization"
      onChoose={handleDelete}
    />,
  ];

  const cols = getCols(options, (org: Organization) =>
    dispatch(setCurrentOrg(org))
  );

  return {
    rowSelection,
    handleSelect,
    selectedRows,
    cols,
    orgs,
    handleDelete,
    editOpen,
    handleEdit,
  };
};
