import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import packageInfo from '../../../package.json';
import { ROUTES } from '../../AppRouting';
import { getCurrentPath } from '../utils';
import { LogoIcon } from './assets/LogoIcon';
import { SupportIcon } from './assets/SupportIcon';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { sendSupportRequest } from '../../store/auth/auth.thunks';

export const Sidebar: React.FC = (): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user);

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const clickSupport = () => {
    if (user) {
      dispatch(sendSupportRequest());
      window.open('https://help.substrata.me/en/', '_blank');
    }
  };

  const isNotIframeCheck = !pathname.includes('personalq_competence');

  return (
    <aside className={isNotIframeCheck ? 'sidebar' : 'hidden sidebar'}>
      <div className="sidebar__top">
        <LogoIcon className="sidebar__logo" />
      </div>
      <nav className="sidebar__nav">
        {ROUTES.map(({ path, label, tooltip, Icon, roles, disabled }) =>
          roles.includes(user?.role as string) && Icon ? (
            <React.Fragment key={label}>
              <Link
                className={`sidebar__nav-link ${
                  pathname === getCurrentPath(path, user) ? 'active' : ''
                } ${disabled ? 'disabled' : ''}`}
                to={disabled ? pathname : getCurrentPath(path, user)}
              >
                <Icon
                  disabled={disabled}
                  className="sidebar__nav-link__icon"
                  active={pathname === getCurrentPath(path, user)}
                  tooltip={tooltip}
                />
              </Link>
            </React.Fragment>
          ) : null
        )}
      </nav>
      <div className="sidebar-support" onClick={clickSupport}>
        <SupportIcon />
      </div>
      <div className="sidebar-version">
        <span>Ver. {packageInfo.version}</span>
      </div>
    </aside>
  );
};
