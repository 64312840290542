import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getCustomToken,
  getIdentity,
  sendSupportRequest,
  signOut,
} from './auth.thunks';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  isLoading: {
    signIn: false,
    customToken: false,
    identity: false,
    signOut: false,
    supportRequest: false,
  },
  isError: {
    signIn: false,
    customToken: false,
    identity: false,
    signOut: false,
    supportRequest: false,
  },
  isLoggedIn: false,
  isInitialized: false,
  customToken: null,
  user: null,
  org: null,
  userSignUpFlow: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = true;
      state.isLoggedIn = state.isLoggedIn || action.payload;
    },
    startSignIn: (state) => {
      state.isError.signIn = false;
      state.isLoading.signIn = true;
    },
    endSignIn: (state) => {
      state.isLoading.signIn = false;
      state.customToken = null;
    },
    throwSignInError: (state) => {
      state.isError.signIn = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomToken.pending, (state) => {
      state.isLoading.customToken = true;
      state.isError.customToken = false;
    });
    builder.addCase(getCustomToken.fulfilled, (state, action) => {
      state.isLoading.customToken = false;
      state.customToken = action.payload;
    });
    builder.addCase(getCustomToken.rejected, (state, action) => {
      state.isLoading.customToken = false;
      state.isError.customToken = !action.meta.aborted;
    });
    builder.addCase(getIdentity.pending, (state) => {
      state.isLoading.identity = true;
      state.isError.identity = false;
    });
    builder.addCase(getIdentity.fulfilled, (state, action) => {
      state.isLoading.identity = false;
      state.user = action.payload.user;
      state.org = action.payload.org;
      state.userSignUpFlow = action.payload.userSignUpFlow;
    });
    builder.addCase(getIdentity.rejected, (state, action) => {
      state.isLoading.identity = false;
      state.isError.identity = !action.meta.aborted;
    });
    builder.addCase(signOut.pending, (state) => {
      state.isLoading.signOut = true;
      state.isError.signOut = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isLoading.signOut = false;
      state.isLoggedIn = false;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.isLoading.signOut = false;
      state.isError.signOut = !action.meta.aborted;
      state.isLoggedIn = false;
    });
    builder.addCase(sendSupportRequest.pending, (state) => {
      state.isLoading.supportRequest = true;
      state.isError.supportRequest = false;
    });
    builder.addCase(sendSupportRequest.fulfilled, (state) => {
      state.isLoading.supportRequest = false;
    });
    builder.addCase(sendSupportRequest.rejected, (state, action) => {
      state.isLoading.supportRequest = false;
      state.isError.supportRequest = !action.meta.aborted;
    });
  },
});

export const {
  initAuth,
  startSignIn,
  endSignIn,
  throwSignInError,
} = authSlice.actions;

export default authSlice.reducer;
