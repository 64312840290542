import React from 'react';

export type ButtonVariant = 'common' | 'text';
export type ButtonType = 'button' | 'submit' | 'reset';

type ButtonProps = {
  children: string | JSX.Element;
  variant: ButtonVariant;
  onClick?: () => void;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  marginTop?: string;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  onClick,
  className = '',
  type = 'button',
  disabled,
  marginTop,
}: ButtonProps): JSX.Element => {
  return (
    <button
      type={type}
      className={`btn btn-${variant} ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={{ marginTop }}
    >
      {children}
    </button>
  );
};
