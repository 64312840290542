import { configureStore, Reducer } from '@reduxjs/toolkit';
import { MODE } from '../constants/config';
import authReducer from './auth/auth.slice';
import { orgsReducer, OrgsState } from './orgs/orgs.reducer';
import orgsNewReducer from './orgs/orgs.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    orgs: orgsReducer as Reducer<OrgsState>,
    orgsNew: orgsNewReducer,
  },
  devTools: MODE === 'local',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
