import React, { CSSProperties } from 'react';

type PopupProps = {
  className?: string;
  open?: boolean;
  options?: JSX.Element[];
  text?: string;
  style?: CSSProperties;
};

export const Popup: React.FC<PopupProps> = ({
  className = '',
  open,
  options = [],
  text,
  style,
}: PopupProps): JSX.Element => {
  return (
    <div
      className={`popup ${className} ${open ? 'open' : 'closed'}`}
      style={style}
    >
      {options.map((option, index) => (
        <React.Fragment key={index}>{option}</React.Fragment>
      ))}
      {text}
    </div>
  );
};
