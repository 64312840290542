import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  BROADCAST_CHANNEL_NAME,
  SIGNUP_LINK,
  SUPER_ACCOUNT_URL,
} from '../../constants/config';
import { getCustomToken } from '../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useFirebase } from '../../context';
import { BroadcastChannelMessageType } from '../../types/broadcastChannel.types';

export const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isSignInError, isInitialized, isLoggedIn, signIn } = useFirebase();

  const user = useAppSelector((state) => state.auth.user);
  const org = useAppSelector((state) => state.auth.org);
  const customToken = useAppSelector((state) => state.auth.customToken);
  const isCustomTokenError = useAppSelector(
    (state) => state.auth.isError.customToken
  );

  const history = useHistory();

  const { search } = useLocation();

  const { authCode } = useMemo(() => {
    const queryParams = new URLSearchParams(search);

    return {
      authCode: queryParams.get('authCode'),
    };
  }, [search]);

  const shouldRedirectToSignUp =
    isSignInError ||
    isCustomTokenError ||
    (!authCode && isInitialized && !isLoggedIn);

  // Exchange auth code for custom token
  useEffect(() => {
    if (authCode) {
      dispatch(getCustomToken(authCode));
    }
  }, [authCode, dispatch, search]);

  // Sign in with custom token
  useEffect(() => {
    if (customToken) {
      signIn(customToken);
    }
  }, [customToken, signIn]);

  // Notify other tabs about logout
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

    if (shouldRedirectToSignUp) {
      broadcastChannel.postMessage({
        type: BroadcastChannelMessageType.LOGOUT,
      });
    }

    return () => {
      broadcastChannel.close();
    };
  }, [shouldRedirectToSignUp]);

  // Handle redirects
  useEffect(() => {
    const hasIdentity = !!user && !!org;

    if (shouldRedirectToSignUp) {
      window.location.href = `${SIGNUP_LINK}/login?client=superAdmin&returnUrl=${encodeURIComponent(
        SUPER_ACCOUNT_URL
      )}`;
      return;
    }

    if (hasIdentity) {
      history.replace('/orgs');
    }
  }, [dispatch, history, org, shouldRedirectToSignUp, user]);

  return null;
};
