import React, { CSSProperties } from 'react';
import { Organization, User } from '../../types';

type AvatarProps = {
  className?: string;
  onClick?: () => void;
  onUpload?: React.ChangeEventHandler<HTMLInputElement>;
  hasUpload?: boolean;
  hasActivityStatus?: boolean;
  image?: string;
  user?: User | null;
  org?: Organization | null;
  style?: CSSProperties;
};

export const Avatar: React.FC<AvatarProps> = ({
  className = '',
  onClick,
  hasUpload = false,
  onUpload,
  image = '',
  hasActivityStatus = false,
  user,
  org,
  style,
}: AvatarProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={`${hasUpload ? 'active' : ''} avatar ${className}`}
    >
      {image ? (
        <img className="avatar__photo" src={image} alt="Avatar" style={style} />
      ) : (
        <div className="avatar__photo" style={style}>
          {(user && user?.firstName?.substring(0, 1)) ||
            (user && user?.name?.substring(0, 1))}
          {user?.lastName?.substring(0, 1)}
          {org && org.name?.substring(0, 1)}
        </div>
      )}
      {hasUpload ? (
        <label htmlFor="avatar-upload" className="avatar__hover">
          <input onChange={onUpload} id="avatar-upload" type="file" />
          Upload
        </label>
      ) : null}
      {hasActivityStatus ? <div className="avatar__active" /> : null}
    </div>
  );
};
