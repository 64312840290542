import { OrgRow } from '../../pages/Organizations/organizations.utils';
import { Features, InviteStatus, Organization } from '../../types';
import { GetOrganizationsResponse } from './orgs.types';

export const getInviteStatus = (
  inviteStatus: InviteStatus
): InviteStatus | undefined =>
  inviteStatus !== 'accepted' ? inviteStatus : undefined;

export const addOrgFeatures = (
  orgs: GetOrganizationsResponse[]
): Organization[] => {
  const result = orgs.map((el) => ({
    ...el,
    enabledFeatures: {
      myInsights: el.enabledFeatures?.includes(Features.MY_INSIGHTS) || false,
      myAnalytics: el.enabledFeatures?.includes(Features.MY_ANALYTICS) || false,
      myEngagements: el.enabledFeatures?.includes(Features.MY_ENGAGEMENTS) || false,
      simulator: el.enabledFeatures?.includes(Features.EMAIL_SIMULATOR) || false,
      inlineMenu: el.enabledFeatures?.includes(Features.INLINE_MENU) || false,
      emailAnalyzer: el.enabledFeatures?.includes(Features.EMAIL_ANALYZER) || false,
      teams: el.enabledFeatures?.includes(Features.TEAMS) || false,
      teamAnalytics: el.enabledFeatures?.includes(Features.TEAM_ANALYTICS) || false,
      eventNotifications:
        el.enabledFeatures?.includes(Features.EVENT_NOTIFICATIONS) || false,
      xRayAnalysis: el.enabledFeatures?.includes(Features.X_RAY_ANALYSIS) || false,
      xRaySimulation: el.enabledFeatures?.includes(Features.X_RAY_SIMULATION) || false,
      webhooks: el.enabledFeatures?.includes(Features.WEBHOOKS) || false,
      soundwave: el.enabledFeatures?.includes(Features.SOUNDWAVE) || false,
    },
  }));
  return result;
};

export const addOrgFeaturesOnUpdate = (
  org: GetOrganizationsResponse
): Organization => {
  const result = {
    ...org,
    enabledFeatures: {
      myInsights: org.enabledFeatures?.includes(Features.MY_INSIGHTS) || false,
      myAnalytics: org.enabledFeatures?.includes(Features.MY_ANALYTICS) || false,
      myEngagements: org.enabledFeatures?.includes(Features.MY_ENGAGEMENTS) || false,
      simulator: org.enabledFeatures?.includes(Features.EMAIL_SIMULATOR) || false,
      inlineMenu: org.enabledFeatures?.includes(Features.INLINE_MENU) || false,
      emailAnalyzer: org.enabledFeatures?.includes(Features.EMAIL_ANALYZER) || false,
      teams: org.enabledFeatures?.includes(Features.TEAMS) || false,
      teamAnalytics: org.enabledFeatures?.includes(Features.TEAM_ANALYTICS) || false,
      eventNotifications:
      org.enabledFeatures?.includes(Features.EVENT_NOTIFICATIONS) || false,
      xRayAnalysis: org.enabledFeatures?.includes(Features.X_RAY_ANALYSIS) || false,
      xRaySimulation: org.enabledFeatures?.includes(Features.X_RAY_SIMULATION) || false,
      webhooks: org.enabledFeatures?.includes(Features.WEBHOOKS) || false,
      soundwave: org.enabledFeatures?.includes(Features.SOUNDWAVE) || false,
    },
  };

  return result;
};

export const updateOrgForTable = (org: Organization): OrgRow => {
  const result = {
    ...org,
    key: org.orgId,
    options: org,
    email: org.primaryEmail,
    userSeats: `${org.usersCount}/${
      org.maxUsersCount === -1 ? 'Unlimited' : org.maxUsersCount
    }`,
    acquisitionSource: org.acquisitionSource || 'Unknown',
    invitationSent: getInviteStatus(org.inviteStatus),
  };

  return result;
};
