import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import './App.scss';
import { AppRouting } from './AppRouting';
import { Sidebar, Header, ClientInterceptors } from './components';
import { store } from './store/store';
import { Login } from './pages/Login/Login';
import { NotFound } from './pages/NotFound/NotFound';
import { NoPermission } from './pages/NoPermission/NoPermission';
import { FirebaseProvider } from './context';

// dG9rOjM4NGE0ZTdlXzMyYmVfNDUzZV84NmMyX2YwZWM1MjZmNGNhYjoxOjA=

const App: React.FC = (): JSX.Element => {
  return (
    <Provider store={store}>
      <FirebaseProvider>
        <ClientInterceptors />
        <Router>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/no-permission">
              <NoPermission />
            </Route>
            <Route path="/not-found">
              <NotFound />
            </Route>
            <Route exact>
              <div className="App">
                <Sidebar />
                <div className="page">
                  <Header />
                  <div className="page__content">
                    <AppRouting />
                  </div>
                </div>
                {/* <FeedbackIcon className="App__feedback" /> */}
              </div>
            </Route>
          </Switch>
        </Router>
      </FirebaseProvider>
    </Provider>
  );
};

export default App;
