import React from 'react';
import { IconProps } from './components';

export enum Features {
  WEBHOOKS = 'webhooks',
  EVENT_NOTIFICATIONS = 'eventNotifications',
  MY_ANALYTICS = 'myAnalytics',
  MY_INSIGHTS = 'myInsights',
  TEAMS = 'teams',
  TEAM_ANALYTICS = 'teamAnalytics',
  EMAIL_ANALYZER = 'emailAnalyzer',
  EMAIL_SIMULATOR = 'emailSimulator',
  X_RAY_ANALYSIS = 'xRayAnalysis',
  X_RAY_SIMULATION = 'xRaySimulation',
  PERSON_ANALYSIS = 'personAnalysis',
  OFFLINE_ANALYSIS = 'offlineAnalysis',
  WATCH_THREADS = 'watchThreads',
  WATCH_CONTACTS = 'watchContacts',
  MY_ENGAGEMENTS = 'myEngagements',
  GROUP_THREADS_BY_CONTACTS = 'groupThreadsByContacts',
  EXTENDED_CONTACTS_METADATA = 'extendedContactsMetadata',
  MESSAGE_WIZARD = 'messageWizard',
  INLINE_MENU = 'inlineMenu',
  PIXEL_TRACKING = 'pixelTracking',
  SOUNDWAVE = 'soundwave',
  CALENDAR_AGENDA = 'calendarAgenda',
  REPLY_FROM_DASHBOARD = 'replyFromDashboard',
}

export type App = {
  name: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  stats: { emailsAnalyzed: number };
};

export type InviteStatus = 'sent' | 'expired' | 'accepted';

export type User = {
  name: string;
  firstName: string;
  lastName: string;
  avatar: string;
  phoneNumber: string;
  timezone: string;
  title: string;
  signupProviders: SignInProvider[];
  userId: string;
  orgId: string;
  email: string;
  apps: App[];
  role: Role;
  lastSignIn: string;
  lastActivity: string;
  createdAt: string;
  updatedAt: string;
  stats: {
    emailsAnalyzed: number;
    updatedAt: string;
  };
  status?: string;
  deleted: boolean;
  active: boolean;
  isEmailVerified: boolean;
  inviteStatus: InviteStatus;
  inviteExpires: string;
  jwt?: string;
};

interface SignInProvider {
  name: string;
  metadata?: {
    default: boolean;
  };
}

type OrgFeatures = {
  myInsights: boolean;
  myAnalytics: boolean;
  myEngagements: boolean;
  simulator: boolean;
  inlineMenu: boolean;
  emailAnalyzer: boolean;
  teams: boolean;
  teamAnalytics: boolean;
  eventNotifications: boolean;
  xRayAnalysis: boolean;
  xRaySimulation: boolean;
  webhooks: boolean;
  soundwave: boolean;
};

export type Organization = {
  name: string;
  url: string;
  primaryEmail: string;
  avatar: string;
  orgId: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  inviteStatus: InviteStatus;
  inviteExpires: string;
  location: string | null;
  usersCount: number;
  maxUsersCount: number;
  acquisitionSource: string | null;
  enabledFeatures?: OrgFeatures;
};

export interface RouteI {
  path: string;
  Component: React.FC;
  label: string;
  Icon: React.FC<IconProps>;
  tooltip?: string;
  roles: string[];
  disabled?: boolean;
}

export type AddedUser = {
  email: string;
  inviteType: 'userInvite' | 'ownerInvite';
  orgId: string;
  name: string;
  avatar?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  userId: string;
};

export type ResendInviteUser = {
  orgId: string;
  userId: string;
  inviteType: 'ownerInvite' | 'userInvite';
  requesterId: string;
};

export enum Role {
  ADMIN = 'admin',
  DEALMAKER = 'dealmaker',
  OWNER = 'owner',
  SUPER_ADMIN = 'superAdmin',
}

export type Row = {
  key: string;
  name: string;
  email: string;
  status: 'active' | 'inactive';
  active?: boolean;
  lastActivity?: string;
  emailsAnalyzed?: number;
  invitationSent?: InviteStatus;
  options?: User;
  userId: string;
  avatar?: string;
  role?: Role;
  orgId?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  teamMemberRole?: 'teamLeader' | 'member';
};

export type AuthCredentials = {
  email: string;
  password: string;
};

export type TeamMember = {
  userId: string;
  teamMemberRole: string;
};

export type AddedTeam = {
  name: string;
  avatar?: string;
  members?: TeamMember[];
};
