import React from 'react';

type TooltipProps = {
  text: string;
  open: boolean;
  width?: string;
};

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  open,
  width,
}: TooltipProps): JSX.Element => {
  return (
    <div
      className={`tooltip tooltip-${open ? 'open' : 'closed'}`}
      style={{ width }}
    >
      {text}
    </div>
  );
};
